import { FC } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import { Box, Button, Container, Divider, Stack, Toolbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUser } from "shared-ts-utils-authentication";
import { McUser } from "firefly/my-chimney/ts/user";
import { UserCard } from "./UserCard";
import { UserSettings } from "./UserSettings";
import { useComponentSizeFromDense } from "@airmont/shared/ts/ui/responsive";
import { useIdentityDao } from "@airmont/shared/ts/ui/identity";

export const UserPage: FC = () => {
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const componentSize = useComponentSizeFromDense();
  const identityDao = useIdentityDao();
  const user = useUser<McUser>();
  const handleLogoutClick = async () => {
    await identityDao.logout();
    window.location.pathname = "/";
  };

  return (
    <AppPage
      $key={"User"}
      name={t("User")}
      mainPaneProps={{ scrollableContentVertically: true }}
      mainPane={
        <Box>
          <Container maxWidth={"xs"}>
            <Stack direction={"column"} useFlexGap gap={1}>
              <UserCard user={user} />
              <Divider />
              <Toolbar
                variant={componentSize === "small" ? "dense" : "regular"}
              >
                <Button
                  size={componentSize}
                  variant={"outlined"}
                  onClick={handleLogoutClick}
                >
                  {t("Logout")}
                </Button>
              </Toolbar>
              <Divider />
              <UserSettings user={user} />
            </Stack>
          </Container>
        </Box>
      }
    />
  );
};
