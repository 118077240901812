import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import React, { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./app/App";
import { boot } from "./app/boot/boot";
import { AppBoot, BootPage } from "@airmont/shared/ts/ui/app-boot";
import {
  AppThemeInitializer,
  AppThemeProvider,
} from "@airmont/shared/ts/ui/app-theme-provider";
import { darkTheme } from "./app/theme/darkTheme";
import { lightTheme } from "./app/theme/lightTheme";
import { I18NextProvider } from "@airmont/shared/ts/utils/i18n";
import { languages } from "./languages";
import {
  ServerSettingsStorage,
  UserSettingsProvider,
} from "@airmont/shared/ts/utils/user-settings";
import { MuiLocalizationProvider } from "shared-ts-mui";
import { AppInfoProvider } from "shared-ts-app-info";
import { appInfo } from "./appInfo";
import i18next from "i18next";
import { WindowSizeProvider } from "@airmont/shared/ts/ui/responsive";
import { Login } from "./app/Login";
import { Box } from "@mui/material";
import { SystemDao } from "@airmont/firefly/my-chimney/ts/system";
import { LoginProvider } from "@airmont/shared/ts/ui/identity";
import { McUser, McUserDto } from "firefly/my-chimney/ts/user";
import { AirmontLogoImg } from "shared-ts-ui-airmont";
import { ConfirmUser } from "./app/confirmUser/ConfirmUser";
import { SetNewPassword } from "./app/SetNewPassword";
import { AcceptUserInvitation } from "./app/acceptUserInvitation/AcceptUserInvitation";
import { RegisterUser } from "./app/registerUser/RegisterUser";
import { PrivacyPolicyPage } from "@airmont/firefly/my-chimney/ts/pages";
import {
  PostHogConsentProvider,
  PostHogIdentifier,
  PostHogInitializer,
} from "shared-ts-posthog";
import { LanguageUserSettingProvider } from "shared-ts-language";
import { LocaleProvider } from "shared-ts-locale";
import { SentryConsentProvider, SentryInitializer } from "shared-ts-sentry";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});
const userSettingsStorage = new ServerSettingsStorage();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const checkAuthenticationProvider = () => {
  return new SystemDao().checkAuthentication;
};

root.render(
  <StrictMode>
    <AppThemeProvider
      initialTheme={
        window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light"
      }
      themes={{
        dark: darkTheme,
        light: lightTheme,
      }}
    >
      <WindowSizeProvider>
        <QueryClientProvider client={queryClient}>
          <AppInfoProvider appInfo={appInfo}>
            <I18NextProvider
              language={localStorage.getItem("ff-mc::language") ?? "nb-NO"}
              languages={languages}
              fallbackLng={{
                "en-US": ["en"],
                "nb-NO": ["no", "en"],
                "nn-NO": ["no", "en"],
              }}
              resources={[
                {
                  namespace: "app",
                  loader: import("./.i18n"),
                },
                {
                  namespace: "firefly-my-chimney-ts-pages",
                  loader: import("@airmont/firefly/my-chimney/ts/pages"),
                },
                {
                  namespace: "firefly-my-chimney-ts-building",
                  loader: import("@airmont/firefly/my-chimney/ts/building"),
                },
                {
                  namespace: "firefly-shared-ts-domain",
                  loader: import("@airmont/firefly/shared/ts/domain"),
                },
                {
                  namespace: "shared-ts-ui-app-boot",
                  loader: import("@airmont/shared/ts/ui/app-boot"),
                },
                {
                  namespace: "shared-ts-ui-identity",
                  loader: import("@airmont/shared/ts/ui/identity"),
                },
                {
                  namespace: "shared-ts-ui-timeframe",
                  loader: import("@airmont/shared/ts/ui/timeframe"),
                },
                {
                  namespace: "shared-ts-release",
                  loader: import("shared-ts-release"),
                },
                {
                  namespace: "shared-ts-app-info",
                  loader: import("shared-ts-app-info"),
                },
                {
                  namespace: "shared-ts-language",
                  loader: import("shared-ts-language"),
                },
                {
                  namespace: "shared-ts-sentry",
                  loader: import("shared-ts-sentry"),
                },
                {
                  namespace: "shared-ts-posthog",
                  loader: import("shared-ts-posthog"),
                },
                {
                  namespace: "shared-ts-mui",
                  loader: import("shared-ts-mui"),
                },
              ]}
              debug={true}
            >
              <LoginProvider<McUserDto>
                loginComponent={(props) => (
                  <Login
                    {...props}
                    sx={{
                      height: "100%",
                    }}
                  />
                )}
                privacyPolicy={{
                  path: "/privacy-policy",
                  component: () => (
                    <PrivacyPolicyPage
                      sx={{
                        height: "100%",
                      }}
                    />
                  ),
                }}
                registerUser={{
                  path: "/register-user",
                  component: () => (
                    <RegisterUser
                      sx={{
                        height: "100%",
                      }}
                    />
                  ),
                }}
                confirmUser={{
                  path: "/confirm-user",
                  component: () => (
                    <ConfirmUser
                      sx={{
                        height: "100%",
                      }}
                    />
                  ),
                }}
                acceptUserInvitation={{
                  path: "/accept-user-invitation",
                  component: () => (
                    <AcceptUserInvitation
                      sx={{
                        height: "100%",
                      }}
                    />
                  ),
                }}
                resetPassword={{
                  path: "/set-new-password",
                  component: () => (
                    <SetNewPassword
                      sx={{
                        height: "100%",
                      }}
                    />
                  ),
                }}
                authenticationCheck={checkAuthenticationProvider()}
                userMapper={(userDto) => new McUser(userDto)}
              >
                {(user) => {
                  return (
                    <UserSettingsProvider storage={userSettingsStorage}>
                      <LanguageUserSettingProvider defaultLanguage={"nb-NO"}>
                        {(language) => {
                          return (
                            <LocaleProvider locale={language}>
                              <MuiLocalizationProvider language={language}>
                                <AppThemeInitializer>
                                  <SentryConsentProvider>
                                    <SentryInitializer
                                      dsn={
                                        "https://7c2637aa9965efd42f3841702d9b548d@o4504604658761728.ingest.sentry.io/4506517905801216"
                                      }
                                    >
                                      <PostHogConsentProvider>
                                        <PostHogInitializer
                                          apiKey={
                                            "phc_VbxRV3Y7anFGuiq4CpwrJ6p9ezlYfEU1ZrNQk29gUF3"
                                          }
                                          options={{
                                            api_host:
                                              "https://eu.i.posthog.com",
                                          }}
                                        >
                                          <PostHogIdentifier user={user}>
                                            <AppBoot
                                              boot={boot}
                                              bootPage={({
                                                messages,
                                                error,
                                              }) => (
                                                <BootPage
                                                  appIcon={<AirmontLogoImg />}
                                                  appName={i18next.t(
                                                    "My Chimney",
                                                    {
                                                      ns: "app",
                                                    }
                                                  )}
                                                  messages={messages}
                                                  error={error}
                                                />
                                              )}
                                              app={(bootResult) => {
                                                return (
                                                  <App
                                                    bootResult={bootResult}
                                                  />
                                                );
                                              }}
                                              appWhenUnauthenticated={() => {
                                                return (
                                                  <Box>Should not hapen</Box>
                                                );
                                              }}
                                            />
                                          </PostHogIdentifier>
                                        </PostHogInitializer>
                                      </PostHogConsentProvider>
                                    </SentryInitializer>
                                  </SentryConsentProvider>
                                </AppThemeInitializer>
                              </MuiLocalizationProvider>
                            </LocaleProvider>
                          );
                        }}
                      </LanguageUserSettingProvider>
                    </UserSettingsProvider>
                  );
                }}
              </LoginProvider>
            </I18NextProvider>
          </AppInfoProvider>
        </QueryClientProvider>
      </WindowSizeProvider>
    </AppThemeProvider>
  </StrictMode>
);
