import { ThemeOptions } from "@mui/material/styles";

const airmontTorqiseDark = "#669999";
const airmontTorqiseLight = "#9ACCCC";
const dark_secondary_container = "#3D5252";
export const darkTheme: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: airmontTorqiseLight,
      container: dark_secondary_container,
    },
    secondary: {
      main: airmontTorqiseDark,
      container: dark_secondary_container,
    },
    background: {
      default: "#181818",
      paper: "#202020",
    },
  },
  shape: {
    borderRadius: 3,
  },
};
