import { ThemeOptions } from "@mui/material/styles";

const airmontTorqiseDark = "#669999";
const airmontTorqiseDarker = "#4c807f";
const turquoiseContainer = "#D1E0E0";
export const lightTheme: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: airmontTorqiseDark,
      container: turquoiseContainer,
    },
    secondary: {
      main: airmontTorqiseDarker,
      container: turquoiseContainer,
    },
    background: {
      default: "#EDFCFC",
      paper: "#FBFBFB",
    },
    action: {
      selected: "rgba(0, 0, 0, 0.14)",
      selectedOpacity: 0.15,
    },
  },
  shape: {
    borderRadius: 3,
  },
};

declare module "@mui/material/styles" {
  interface PaletteColor {
    container?: string;
    onContainer?: string;
  }

  interface SimplePaletteColorOptions {
    container?: string;
    onContainer?: string;
  }

  interface Palette {
    tertiary?: Palette["primary"];
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions["primary"];
  }
}
