import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { IdentityUserDto } from "./IdentityUser";
import { SendResetPasswordEmailRequest } from "./SendResetPasswordEmailRequest";
import { ResetPasswordRequest } from "./ResetPasswordRequest";
import { ConfirmAccountRequest } from "./ConfirmAccountRequest";
import { LoginRequest } from "./LoginRequest";
import { LoginState } from "./LoginState";
import { AcceptInvitationRequest } from "./AcceptInvitationRequest";
import { InvitedIdentityUserDto } from "./InvitedIdentityUser";
import { GetInvitedUserResponse } from "./GetInvitedUserResponse";

export interface ResetPasswordResponse {
  result: ResetPasswordResult;
  description?: string;
}
export type ResetPasswordResult = "succeeded" | "failed" | "invalid-token";
export class IdentityDao {
  private readonly fetchHelper: FetchHelper;

  constructor() {
    this.fetchHelper = new FetchHelper({
      basePath: "/api/identity/",
    });
  }

  loginUsingCookies = async (request: LoginRequest): Promise<LoginState> => {
    const response = await this.fetchHelper
      .post("login?useCookies=true", request)
      .fetchAsResponse({ contentType: "application/json", ignoreError: true });
    if (response.ok) {
      return Promise.resolve("Success");
    } else {
      return Promise.resolve("Unauthorized");
    }
  };

  async confirmAccount(request: ConfirmAccountRequest): Promise<boolean> {
    const queryParams: Record<string, string> = {
      userId: request.userId,
      code: request.code,
    };
    try {
      await this.fetchHelper.get("confirm-email", queryParams).fetch();
      return true;
    } catch (e) {
      console.error("Error while confirming account: ", e);
      return false;
    }
  }

  logout(): Promise<void> {
    return this.fetchHelper.get("logout").fetch();
  }

  getInvitedUser<T extends InvitedIdentityUserDto>(
    token: string,
    userId: string
  ): Promise<GetInvitedUserResponse<T>> {
    return this.fetchHelper
      .get(`get-invited-user?token=${token}&userId=${userId}`)
      .fetchAsJson();
  }

  acceptInvitation(request: AcceptInvitationRequest): Promise<void> {
    return this.fetchHelper.post("accept-invitation", request).fetch();
  }

  getUser<T extends IdentityUserDto>(): Promise<T> {
    return this.fetchHelper.get("get-user").fetchAsJson();
  }
  sendResetPasswordEmail = async (
    request: SendResetPasswordEmailRequest
  ): Promise<void> => {
    return this.fetchHelper.get("send-reset-password-email", request).fetch();
  };

  resetPassword = async (
    request: ResetPasswordRequest
  ): Promise<ResetPasswordResponse> => {
    const response = await this.fetchHelper
      .post("reset-password", request)
      .fetchAsResponse({ contentType: "application/json", ignoreError: true });

    if (response.status === 200) {
      return { result: "succeeded" };
    } else if (response.status === 406) {
      return { result: "invalid-token" };
    } else {
      const description = await response.text();
      return { result: "failed", description: description };
    }
  };
}
