import { FC, ReactNode, useEffect, useState } from "react";
import { Card, Stack, Typography, useTheme } from "@mui/material";
import { useMetricColors } from "./useMetricColors";
import { keyframes } from "styled-components";
import { TooltipOrNot } from "@airmont/shared/ts/ui/tooltip";

const valueChangeAnimation = (color: string) => keyframes`
  0% {
    color: rgba(${color}, 0.2);
  }
  30% {
    color: rgba(${color}, 0.4);
  }
  70% {
    color: rgba(${color}, 0.8);
  }
  100% {
    color: rgba(${color}, 1);
  }
`;

export interface MetricProps {
  label: string;
  value?: number;
  unit?: string;
  tooltip?: ReactNode | undefined;
}

export const Metric: FC<MetricProps> = (props) => {
  const theme = useTheme();
  const { backgroundColor, textColor } = useMetricColors();
  const [showValueChangeAnimation, setShowValueChangeAnimation] =
    useState(false);

  useEffect(() => {
    setShowValueChangeAnimation(true);
    setTimeout(() => {
      setShowValueChangeAnimation(false);
    }, 1000);
  }, [props.value]);
  return (
    <Stack direction={"column"} useFlexGap gap={1}>
      <Typography align={"center"}>{props.label}</Typography>
      <TooltipOrNot title={props.tooltip}>
        <Card
          sx={{
            height: "50px",
            borderStyle: "outset",
            borderColor: theme.palette.divider,
            borderWidth: "3px",
            borderRadius: "6px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignContent: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Typography
            className={"value"}
            align={"center"}
            sx={{
              animation: showValueChangeAnimation ? `move 1s ease` : undefined,
              "@keyframes move": {
                "0%": {
                  opacity: 0.2,
                },
                "30%": {
                  opacity: 0.4,
                },
                "70%": {
                  opacity: 0.8,
                },
                "100%": {
                  opacity: 1,
                },
              },
            }}
          >
            {props.value}
          </Typography>
          {props.unit != null && <Typography>{props.unit}</Typography>}
        </Card>
      </TooltipOrNot>
    </Stack>
  );
};
