import { useMemo } from "react";
import { BurnDao } from "./BurnDao";
import { CustomerEnvironmentId } from "@airmont/firefly/shared/ts/customer-environment";

export const useBurnDao = (args: {
  customerEnvironment: CustomerEnvironmentId;
}): BurnDao => {
  return useMemo(
    () =>
      new BurnDao({
        accessToken: undefined,
        customerEnvironment: args.customerEnvironment,
      }),
    [args.customerEnvironment]
  );
};
