import { FC, useEffect, useMemo, useRef, useState } from "react";
import { FlueMetricTimeItem } from "./FlueMetricTimeItem";
import {
  FlueMetricNaturalKey,
  FlueMetricsTimeUnitEnum,
} from "@airmont/firefly/shared/ts/domain";
import { Stack, SxProps, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Button_color, useSxMerge } from "shared-ts-mui";
import { useHotkeys } from "react-hotkeys-hook";
import { Key } from "ts-key-enum";
import { useResizeDetector } from "react-resize-detector";
import { ObjectUtils } from "@airmont/shared/ts/utils/core";

export interface FlueMetricTimeSelectProps {
  items: Array<FlueMetricNaturalKey>;
  selected: FlueMetricNaturalKey;
  onSelect: (item: FlueMetricNaturalKey) => void;
  sx?: SxProps;
  color?: Button_color;
}
export const FlueMetricTimeSelect: FC<FlueMetricTimeSelectProps> = (props) => {
  const { items, selected } = props;
  const firstRowRef = useRef<HTMLDivElement>(null);
  const { width: rootWidth, ref: rootRef } = useResizeDetector<HTMLDivElement>({
    handleHeight: false,
    handleWidth: true,
  });
  const { width: secondRowWidth, ref: secondRowRef } =
    useResizeDetector<HTMLDivElement>({
      handleHeight: false,
      handleWidth: true,
    });
  const [firstRowContentIsOverflowing, setFirstRowContentIsOverflowing] =
    useState<boolean>(false);
  const [secondRowContentIsOverflowing, setSecondRowContentIsOverflowing] =
    useState<boolean>(false);
  const ever = useMemo(
    () => items.find((it) => it.unit === FlueMetricsTimeUnitEnum.Ever),
    [items]
  );
  const sinceSweep = useMemo(
    () => items.find((it) => it.unit === FlueMetricsTimeUnitEnum.SinceSweep),
    [items]
  );
  const hasEver = useMemo(() => ever !== undefined, [ever]);
  const hasSinceSweep = useMemo(() => sinceSweep !== undefined, [sinceSweep]);
  const flueMetricsTimeUnit = selected.unit;
  const filteredItems = useMemo(() => {
    return items.filter(
      (it) =>
        flueMetricsTimeUnit === it.unit &&
        !(
          flueMetricsTimeUnit === FlueMetricsTimeUnitEnum.SinceSweep ||
          flueMetricsTimeUnit === FlueMetricsTimeUnitEnum.Ever
        )
    );
  }, [items, flueMetricsTimeUnit]);
  useHotkeys(Key.ArrowLeft, () => {
    const currIndex = items.findIndex((it) => {
      return ObjectUtils.equals(it, selected);
    });
    if (currIndex > 0) {
      const prevItem = items[currIndex - 1];
      if (prevItem !== undefined) {
        props.onSelect(prevItem);
      }
    }
  });

  useHotkeys(Key.ArrowRight, () => {
    const currIndex = items.findIndex((it) => {
      return ObjectUtils.equals(it, selected);
    });
    if (currIndex < items.length - 1) {
      const nextItem = items[currIndex + 1];
      if (nextItem !== undefined) {
        props.onSelect(nextItem);
      }
    }
  });

  useEffect(() => {
    const el = firstRowRef.current;
    if (el != null) {
      const overFlowInPixels =
        rootWidth !== undefined ? el.scrollWidth - rootWidth : 0;
      const remainingSpace =
        rootWidth !== undefined ? rootWidth - el.scrollWidth : 0;

      if (overFlowInPixels > 0) {
        setFirstRowContentIsOverflowing(overFlowInPixels > 0);
      } else if (remainingSpace > 100) {
        setFirstRowContentIsOverflowing(false);
      }
    }
  }, [rootWidth, firstRowRef]);

  useEffect(() => {
    const el = secondRowRef.current;
    if (el != null) {
      const overFlowInPixels = el.scrollWidth - el.clientWidth;
      setSecondRowContentIsOverflowing(overFlowInPixels > 0);
    }
  }, [secondRowWidth, secondRowRef]);

  const handleFlueMetricsTimeUnitChange = (
    event: React.MouseEvent<HTMLElement>,
    newFlueMetricsTimeUnit: FlueMetricsTimeUnitEnum
  ) => {
    if (
      newFlueMetricsTimeUnit === FlueMetricsTimeUnitEnum.Ever &&
      ever !== undefined
    ) {
      props.onSelect(ever);
    } else if (
      newFlueMetricsTimeUnit === FlueMetricsTimeUnitEnum.SinceSweep &&
      sinceSweep !== undefined
    ) {
      props.onSelect(sinceSweep);
    } else if (newFlueMetricsTimeUnit === FlueMetricsTimeUnitEnum.Year) {
      const firstYear = items.find(
        (it) => it.unit === FlueMetricsTimeUnitEnum.Year
      );
      if (firstYear) {
        props.onSelect(firstYear);
      }
    } else if (newFlueMetricsTimeUnit === FlueMetricsTimeUnitEnum.Month) {
      const firstMonth = items.find(
        (it) => it.unit === FlueMetricsTimeUnitEnum.Month
      );
      if (firstMonth) {
        props.onSelect(firstMonth);
      }
    }
  };

  const sx = useSxMerge(props.sx, {
    minHeight: "96px",
    alignItems: "center",
  });
  return (
    <Stack direction={"column"} useFlexGap gap={1} sx={sx} ref={rootRef}>
      <ToggleButtonGroup
        ref={firstRowRef}
        exclusive
        color={"primary"}
        value={flueMetricsTimeUnit}
        onChange={handleFlueMetricsTimeUnitChange}
        sx={{ overflowX: "auto" }}
      >
        {hasEver && (
          <ToggleButton
            value={FlueMetricsTimeUnitEnum.Ever}
            size={"small"}
            sx={{ whiteSpace: "nowrap" }}
          >
            Siden start
          </ToggleButton>
        )}
        {hasSinceSweep && (
          <ToggleButton
            value={FlueMetricsTimeUnitEnum.SinceSweep}
            size={"small"}
            sx={{ whiteSpace: "nowrap" }}
          >
            Siden feiing
          </ToggleButton>
        )}
        <ToggleButton
          value={FlueMetricsTimeUnitEnum.Year}
          size={"small"}
          sx={{ whiteSpace: "nowrap" }}
        >
          {firstRowContentIsOverflowing ? "År" : "Per År"}
        </ToggleButton>
        <ToggleButton
          value={FlueMetricsTimeUnitEnum.Month}
          size={"small"}
          sx={{ whiteSpace: "nowrap" }}
        >
          {firstRowContentIsOverflowing ? "Måned" : "Per Måned"}
        </ToggleButton>
      </ToggleButtonGroup>
      <Stack
        ref={secondRowRef}
        direction={"row"}
        sx={{
          alignSelf: secondRowContentIsOverflowing ? "start" : "auto",
          overflowX: "auto",
          overflowY: "visible",
          position: "relative",
          "> .MuiButton-root": {
            marginBottom: "5px",
            marginRight: "5px",
            marginLeft: "2px",
            marginTop: "2px",
          },
        }}
      >
        {filteredItems.map((item) => {
          const key = `${item.unit}:${item.time?.toISO() ?? ""}`;
          const isSelected =
            ((selected.unit === FlueMetricsTimeUnitEnum.Ever ||
              selected.unit === FlueMetricsTimeUnitEnum.SinceSweep) &&
              selected.unit === item.unit) ||
            (selected.unit === item.unit &&
              item?.time !== undefined &&
              selected.time !== undefined &&
              selected.time.equals(item.time));
          return (
            <FlueMetricTimeItem
              key={key}
              value={item}
              color={props.color}
              selected={isSelected}
              onSelect={props.onSelect}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
