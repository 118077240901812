import {
  BurnId,
  BurnTemperatureSeries,
  BurnTemperatureSeriesImpl,
  FlueMetricsTimeUnitEnum,
} from "@airmont/firefly/shared/ts/domain";
import React, { FC, useMemo } from "react";
import {
  TimeSeries,
  TimeSeriesImpl,
} from "@airmont/firefly/shared/ts/timeseries";
import { SxProps } from "@mui/material";
import {
  TimeAxisValueFormatterFactory,
  TimeSeriesMuiChart,
  TimeSeriesMuiChartContainer,
} from "@airmont/firefly/shared/ts/timeseries-mui-chart";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import { ChartsReferenceLine } from "@mui/x-charts";
import { useResolveMaxTemperatureReferenceLineProps } from "./useResolveMaxTemperatureReferenceLineProps";
import { DateTime } from "luxon";
import { XAxisDefaultConfig, YAxisDefaultConfig } from "shared-ts-mui";

const unit = "°C";

export interface BurnTimeSeriesSensitiveChartProps {
  flue: Flue;
  burnTimeSeries?: Array<BurnTemperatureSeries>;
  anonymizedTime?: boolean;
  selected: Array<BurnId>;
  selectedMonth?: DateTime;
  sx?: SxProps;
}

export const BurnTimeSeriesSensitiveChart: FC<
  BurnTimeSeriesSensitiveChartProps
> = (props) => {
  const { flue, burnTimeSeries, selected, selectedMonth } = props;
  const anonymizedTime = props.anonymizedTime ?? false;
  const flueMetricsSinceStart = flue.querySingleMetricsOrUndef(
    FlueMetricsTimeUnitEnum.Ever
  );

  const maxTemperatureSinceStart =
    flueMetricsSinceStart?.metrics.temperatureMax;

  const chartYReferenceLineProps = useResolveMaxTemperatureReferenceLineProps(
    flue,
    unit,
    selectedMonth
  );

  const timeSeriesArray: Array<TimeSeries<number | null>> | undefined =
    useMemo(() => {
      return burnTimeSeries
        ?.filter((it) => selected.includes(it.burnId))
        .map((burnSeries) => {
          return new TimeSeriesImpl({
            info: {
              id: `${burnSeries.burnId}`,
              sid: flue.id,
              name: "",
              resolution: "",
              unit: unit,
            },
            points: burnSeries.dataPoints,
          });
        });
    }, [burnTimeSeries, selected, flue.id]);

  const yAxisConfig: Array<YAxisDefaultConfig> = useMemo(() => {
    const minY =
      burnTimeSeries !== undefined
        ? BurnTemperatureSeriesImpl.getMinY(burnTimeSeries, 0)
        : 0;

    return [
      {
        max:
          maxTemperatureSinceStart !== undefined
            ? maxTemperatureSinceStart
            : undefined,
        min: minY,
      } as YAxisDefaultConfig,
    ];
  }, [burnTimeSeries, maxTemperatureSinceStart]);

  const xAxisConfig: XAxisDefaultConfig | undefined = useMemo(() => {
    return {
      valueFormatter: TimeAxisValueFormatterFactory.create({
        formatOptions: DateTime.TIME_24_SIMPLE,
      }),
    };
  }, []);

  return (
    <TimeSeriesMuiChartContainer sx={props.sx}>
      <TimeSeriesMuiChart
        timeSeries={timeSeriesArray ?? []}
        yAxisConfig={yAxisConfig}
        xAxisConfig={xAxisConfig}
      >
        {chartYReferenceLineProps.map((it) => {
          return <ChartsReferenceLine key={it.label} {...it} />;
        })}
      </TimeSeriesMuiChart>
    </TimeSeriesMuiChartContainer>
  );
};
