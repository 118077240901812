import { Month } from "./Month";
import { FC, useMemo } from "react";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";
import { Info } from "luxon";
import { Button, Stack, SxProps, Typography } from "@mui/material";
import { Button_color } from "shared-ts-mui";
import { FlueMetrics } from "@airmont/firefly/shared/ts/domain";
import WhatshotRoundedIcon from "@mui/icons-material/WhatshotRounded";
import { merge } from "lodash";
import { red } from "@mui/material/colors";

export interface MonthItemProps {
  month: Month;
  metrics?: FlueMetrics;
  selected?: boolean;
  color?: Button_color;
  sx?: SxProps;
  onSelect: (value: Month) => void;
}

export const MonthItem: FC<MonthItemProps> = (props) => {
  const { month, selected, metrics } = props;
  const now = useDateTimeNow();
  const monthName = Info.months()[month.dateTime.month - 1];
  const burnCount = metrics?.metrics.burnCount ?? 0;
  const sx: SxProps = useMemo(() => {
    return merge({}, props.sx, {
      minWidth: "fit-content",
      pl: 0,
      pr: 0,
      pt: 0,
      pb: 0,
      borderColor: selected ? "text.secondary" : "transparent",
    } as SxProps);
  }, [props.sx, selected]);
  const handleClick = () => {
    props.onSelect(month);
  };
  return (
    <Button
      color={selected ? "primary" : "inherit"}
      variant={selected ? "contained" : "text"}
      onClick={handleClick}
      sx={sx}
    >
      {
        <Stack
          direction={"column"}
          sx={{
            alignItems: "center",
            pt: "6px",
            pl: 2,
            pr: 2,
            pb: 1,
          }}
        >
          <Typography variant={"button"}>
            {now.year !== month.dateTime.year && month.dateTime.year + " "}
            {monthName}
          </Typography>
          <Typography
            noWrap
            sx={{ fontSize: "smaller", display: "flex", alignItems: "center" }}
          >
            {burnCount}
            <WhatshotRoundedIcon
              sx={{
                ml: 1,
                fontSize: "14px",
                color:
                  burnCount > 15
                    ? red["400"]
                    : burnCount > 7
                    ? red["300"]
                    : burnCount > 0
                    ? red["200"]
                    : undefined,
                filter: selected
                  ? "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.9))"
                  : undefined,
              }}
            />
          </Typography>
        </Stack>
      }
    </Button>
  );
};
