import { environment } from "../../environments/environment";
import { bootDemo } from "./demo/bootDemo";
import { SystemDao } from "@airmont/firefly/my-chimney/ts/system";
import { MyChimneyBootResult } from "./MyChimneyBootResult";

export const boot = async (args: {
  onMessage: (message: string) => void;
  onError: (error: Error) => void;
}): Promise<MyChimneyBootResult> => {
  const useBackend = await resolveUseBackend();
  console.info(" useBackend " + useBackend);
  if (!useBackend) {
    args.onMessage("Genererer demo data.");
    await bootDemo();
  }

  return Promise.resolve({});
};

const resolveUseBackend = async (): Promise<boolean> => {
  if (environment.production) {
    return Promise.resolve(true);
  }
  try {
    await new SystemDao().ping();
    return Promise.resolve(true);
  } catch (e) {
    return Promise.resolve(false);
  }
};
