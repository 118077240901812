import React, { Attributes, FC, useState } from "react";
import { Box, Button, Card, SvgIcon } from "@mui/material";
import { Deck } from "@airmont/shared/ts/ui/deck";
import { FluePanel } from "./FluePanel";
import { Chimney } from "@airmont/firefly/my-chimney/ts/building";
import { ChimneyIllustration } from "./ChimneyIllustration";
import { FlueMetricNaturalKey } from "@airmont/firefly/shared/ts/domain";
import { CustomerEnvironmentId } from "@airmont/firefly/shared/ts/customer-environment";

export interface ChimneyPanelProps extends Attributes {
  customerEnvironment: CustomerEnvironmentId;
  chimney: Chimney;
  selectedFlueMetric: FlueMetricNaturalKey;
  onGotoNextChimney?: () => void;
  onGotoPreviousChimney?: () => void;
}

export const ChimneyPanel: FC<ChimneyPanelProps> = (props) => {
  const { chimney } = props;
  const [selectedFlue] = useState<number>(0);
  const multipleFlues = chimney.flues.length > 1;

  return (
    <Card
      className={"Chimney"}
      elevation={0}
      sx={{
        backgroundColor: "inherit",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr auto 1fr",
          alignItems: "stretch",
        }}
      >
        <Box
          className={"RoofLeft"}
          sx={{ display: "flex", alignItems: "stretch" }}
        >
          <SvgIcon
            inheritViewBox
            sx={{
              flexGrow: 1,
              height: "100px",
              opacity: 0,
              minWidth: 0,
              minHeight: 0,
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
              <line
                x1="0"
                y1="100"
                x2="100"
                y2="0"
                vectorEffect="non-scaling-stroke"
                stroke="red"
              />
            </svg>
          </SvgIcon>
          {props.onGotoPreviousChimney != null && (
            <Button
              sx={{ justifySelf: "start" }}
              onClick={props.onGotoPreviousChimney}
            >
              Prev
            </Button>
          )}
          {props.onGotoPreviousChimney == null && (
            <Box className={"Placeholder"} sx={{ justifySelf: "start" }}>
              {/* Placeholder */}
            </Box>
          )}
        </Box>
        <ChimneyIllustration chimney={chimney} />
        <Box className={"RoofRight"}>
          {props.onGotoNextChimney != null && (
            <Button
              sx={{ justifySelf: "end" }}
              onClick={props.onGotoNextChimney}
            >
              Next
            </Button>
          )}
          {props.onGotoNextChimney == null && (
            <Box className={"Placeholder"} sx={{ justifySelf: "end" }}>
              {/* Placeholder */}
            </Box>
          )}
        </Box>
      </Box>
      <Deck
        className={"FlueDeck"}
        selected={selectedFlue}
        sx={{
          width: "100%",
          flexGrow: 1,
          minWidth: "fit-content",
          minHeight: 0,
        }}
      >
        {chimney.flues.map((flue, index) => {
          return (
            <FluePanel
              key={flue.id}
              flue={flue}
              hideName={!multipleFlues}
              customerEnvironment={props.customerEnvironment}
              selectedFlueMetric={props.selectedFlueMetric}
            />
          );
        })}
      </Deck>
    </Card>
  );
};
