import { FetchHelper } from "@airmont/shared/ts/utils/fetch";

export class SystemDao {
  private fetchHelper: FetchHelper;

  constructor() {
    this.fetchHelper = new FetchHelper({ basePath: "/api/system/" });
  }

  async ping(): Promise<string> {
    return await this.fetchHelper.get("ping").fetchAsText();
  }

  getBuildNumber(): Promise<string> {
    return this.fetchHelper.get("get-build-number").fetchAsText();
  }

  checkAuthentication = async (): Promise<boolean> => {
    try {
      const response = await this.fetchHelper
        .get("check-authentication")
        .fetchAsResponse();
      return Promise.resolve(response.ok);
    } catch (e) {
      return Promise.resolve(false);
    }
  };
}
