import { FC, ReactNode, useMemo } from "react";
import {
  Burn,
  BurnTemperatureSeries,
  BurnTemperatureSeriesImpl,
  FlueId,
} from "@airmont/firefly/shared/ts/domain";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import {
  _throw,
  IllegalStateError,
  notUndef,
} from "@airmont/shared/ts/utils/core";
import { useBurnDao } from "./useBurnDao";
import { CustomerEnvironmentId } from "@airmont/firefly/shared/ts/customer-environment";

export interface BurnsLoaderProps {
  environment: CustomerEnvironmentId;
  flueId: FlueId;
  fromDate: DateTime;
  toDate: DateTime;
  children: (props: {
    burns: Array<Burn> | undefined;
    isAnonymizedTime: boolean | undefined;
    isBurnsLoading: boolean;
    isBurnsFetching: boolean;
    temperatureSeries: Array<BurnTemperatureSeries> | undefined;
    isTemperatureSeriesLoading: boolean;
    isTemperatureSeriesFetching: boolean;
  }) => ReactNode;
}

export const BurnsLoader: FC<BurnsLoaderProps> = (props) => {
  const burnDao = useBurnDao({ customerEnvironment: props.environment });
  const {
    data: burnDtos,
    isLoading: isBurnsLoading,
    isFetching: isBurnsFetching,
  } = useQuery({
    queryKey: [
      "BurnDao.getByFlue",
      props.flueId,
      props.fromDate,
      props.toDate,
      props.environment,
    ],
    queryFn: () =>
      burnDao.getByFlue(
        props.environment,
        props.flueId,
        props.fromDate,
        props.toDate
      ),
    placeholderData: (previousData, previousQuery) => previousData,
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });
  const burnsResult = useMemo(() => {
    const countByDate: Record<string, number> = {};

    if (burnDtos === undefined) {
      return undefined;
    }
    const reversed = burnDtos.slice().reverse();
    let isAnonymizedTime = false;
    const withDayCount = reversed
      .map((it, index) => {
        if (!isAnonymizedTime && it.anonymized) {
          isAnonymizedTime = true;
        }
        const startTimeAsDateTime = DateTime.fromISO(it.startTime);
        const startTimeDate = startTimeAsDateTime.toFormat("yyyy-MM-dd");
        let dayCount = countByDate[startTimeDate];
        if (dayCount == null) {
          dayCount = 1;
          countByDate[startTimeDate] = dayCount;
        } else {
          dayCount++;
          countByDate[startTimeDate] = dayCount;
        }
        return new Burn(it, { count: index + 1, countOfDay: dayCount });
      })
      .reverse();
    const result: Array<Burn> = [];
    withDayCount.forEach((burn, index) => {
      const prevBurn = index > 0 ? withDayCount[index - 1] : undefined;
      if (
        burn.startTime.day !== prevBurn?.startTime.day &&
        burn.countOfDay === 1
      ) {
        result.push(
          new Burn(
            { ...burn.toDto() },
            { count: burn.count, countOfDay: undefined }
          )
        );
      } else {
        result.push(burn);
      }
    });
    return { burns: result, isAnonymizedTime: isAnonymizedTime };
  }, [burnDtos]);

  const burnIds = useMemo(
    () => burnsResult?.burns?.map((it) => it.id),
    [burnsResult]
  );
  const {
    data: temperatureSeriesDtos,
    isLoading: isTemperatureSeriesLoading,
    isFetching: isTemperatureSeriesFetching,
  } = useQuery({
    queryKey: [
      "BurnDao.getTemperatureSeriesByBurn",
      burnIds,
      props.environment,
    ],
    queryFn: () =>
      notUndef(burnIds, (burnIds) =>
        burnDao.getTemperatureSeriesByBurn(props.environment, burnIds)
      ) ?? _throw(new IllegalStateError("burnIds undefined")),
    enabled: burnIds !== undefined,
    placeholderData: (previousData, previousQuery) => previousData,
    staleTime: 10 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });
  const temperatureSeries = useMemo(() => {
    return temperatureSeriesDtos?.map((dto) =>
      BurnTemperatureSeriesImpl.fromDto(dto)
    );
  }, [temperatureSeriesDtos]);

  return props.children({
    burns: burnsResult?.burns,
    isAnonymizedTime: burnsResult?.isAnonymizedTime,
    isBurnsLoading: isBurnsLoading,
    isBurnsFetching: isBurnsFetching,
    temperatureSeries: temperatureSeries,
    isTemperatureSeriesLoading: isTemperatureSeriesLoading,
    isTemperatureSeriesFetching: isTemperatureSeriesFetching,
  });
};
