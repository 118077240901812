import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { AirmontLogoImg } from "shared-ts-ui-airmont";
import { useSxMerge } from "shared-ts-mui";
import { useTranslation } from "react-i18next";
import { PrivacyPolicyContent } from "./PrivacyPolicyContent";
import {
  useWindowHeightComparer,
  useWindowWidthComparer,
} from "@airmont/shared/ts/ui/responsive";

export interface PrivacyPolicyPageProps {
  sx?: SxProps;
}

export const PrivacyPolicyPage: FC<PrivacyPolicyPageProps> = (props) => {
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const windowWithComparer = useWindowWidthComparer();
  const windowHeightComparer = useWindowHeightComparer();

  const sx = useSxMerge(props.sx, {
    flex: 1,
    minHeight: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  });

  const detailsMaxHeight =
    windowHeightComparer.sizeClass === "Compact"
      ? "200px"
      : windowHeightComparer.sizeClass === "Medium"
      ? "400px"
      : windowHeightComparer.sizeClass === "Expanded"
      ? "600px"
      : "800px";

  return (
    <Container
      maxWidth={
        windowWithComparer.sizeClass === "Compact"
          ? "xs"
          : windowWithComparer.sizeClass === "Medium"
          ? "xs"
          : windowWithComparer.sizeClass === "Expanded"
          ? "sm"
          : "md"
      }
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Stack direction={"column"} useFlexGap gap={2} sx={sx}>
        <AirmontLogoImg />
        <Typography variant={"h2"} align={"center"} sx={{ mb: 2 }}></Typography>
        <Accordion expanded={true}>
          <AccordionSummary sx={{ backgroundColor: "primary.light" }}>
            {t("My Chimney")} {t("Privacy Policy")}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              maxHeight: detailsMaxHeight,
              overflowY: "auto",
            }}
          >
            <PrivacyPolicyContent />
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Container>
  );
};
