import { Typography } from "@mui/material";
import React, { FC } from "react";

export const UserRegistrationPrivacyPolicyContent: FC = (props) => {
  return (
    <>
      <Typography>
        Som bruker av appen Min Pipe (My Chimney) samtykker du til at:
      </Typography>
      <ul>
        <Typography component={"li"}>
          Vi bruker ditt fødsel- og personnummer (fra Bank-ID) til å slå opp din
          folkeregistrerte adresse hos Folkeregisteret. Fødsel- og
          personnummeret ditt blir ikke lagret.
        </Typography>
        <Typography component={"li"} sx={{ pt: 1 }}>
          Vi lagrer ditt fulle navn, registrerte e-post-adresse og
          folkeregistrerte adresse.
          <ul>
            <Typography component={"li"}>
              Navnet lagrer vi for å kunne tiltale deg med navn.
            </Typography>
            <Typography component={"li"}>
              E-postadressen lagrer vi for å kunne nå deg som bruker av Min Pipe
              når det skulle trengs.
            </Typography>
            <Typography component={"li"}>
              Adressen trenger vi for å kunne vite hvilke Pipesensor-data du har
              krav på å se. Brukere av appen skal kun få se data fra adresser de
              bor på.
            </Typography>
          </ul>
        </Typography>
      </ul>
    </>
  );
};
