import React, { FC } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import { Box, Stack, Toolbar } from "@mui/material";
import { Deck } from "@airmont/shared/ts/ui/deck";
import { useMyChimneyContext } from "../MyChimneyContext";
import { ChimneyPanel } from "./ChimneyPanel";
import { BuildingSelect } from "@airmont/firefly/my-chimney/ts/building";
import { AddressId, FlueId } from "@airmont/firefly/shared/ts/domain";
import { FlueSelect } from "../shared/FlueSelect";
import { FlueMetricTimeSelect } from "../shared/flueMetricTimeSelect/FlueMetricTimeSelect";
import { useResizeDetector } from "react-resize-detector";
import { useTranslation } from "react-i18next";
import { useFlueMetricTimeItems } from "../shared/flueMetricTimeSelect/useFlueMetricTimeItems";

export const KeyFiguresPage: FC = () => {
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const { width, ref } = useResizeDetector({ handleHeight: false });
  const {
    buildings,
    selectedBuilding,
    setSelectedBuilding,
    selectedChimney,
    selectedFlue,
    setSelectedFlue,
    gotoNextChimney,
    gotoPreviousChimney,
  } = useMyChimneyContext();
  const indexOfSelectedChimney =
    selectedBuilding.getIndexOfChimney(selectedChimney);
  const {
    flueMetricTimeItems,
    selectedFlueMetricTimeItem,
    handleFlueMetricTimeItemSelect,
  } = useFlueMetricTimeItems(
    "KeyFiguresPage",
    selectedFlue.id,
    selectedFlue.metrics
  );
  const handleBuildingChange = (value: AddressId) => {
    setSelectedBuilding(value);
  };
  const handleSelectedFlue = (flueId: FlueId) => {
    setSelectedFlue(flueId);
  };

  /*const handleGotoNextChimney = () => {
    gotoNextChimney();
  };*/

  /*const handleGotoPreviousChimney = () => {
    gotoPreviousChimney();
  };*/

  return (
    <AppPage
      $key={"KeyFiguresPage"}
      name={t("Overview")}
      hideName
      mainPane={
        <Box
          sx={{
            flexGrow: 1,
            minWidth: 0,
            minHeight: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Stack
            direction={"column"}
            gap={1}
            useFlexGap
            sx={{ flexGrow: 1 }}
            ref={ref}
          >
            <Toolbar disableGutters variant={"dense"}>
              <BuildingSelect
                buildings={buildings}
                selected={selectedBuilding.id}
                onChange={handleBuildingChange}
              />
              {selectedBuilding.getNumberOfFlues() > 1 && (
                <FlueSelect
                  building={selectedBuilding}
                  selectedFlue={selectedFlue}
                  onSelectFlue={handleSelectedFlue}
                  sx={{ ml: 1 }}
                />
              )}
            </Toolbar>
            <Toolbar disableGutters sx={{ display: "flex" }}>
              <FlueMetricTimeSelect
                items={flueMetricTimeItems}
                selected={selectedFlueMetricTimeItem}
                onSelect={handleFlueMetricTimeItemSelect}
                color={"inherit"}
                sx={{ flex: 1, maxWidth: width }}
              />
            </Toolbar>
            <Deck
              className={"ChimneyDeck"}
              sx={{
                width: "100%",
                flexGrow: 1,
                minWidth: 0,
                minHeight: 0,
              }}
              selected={indexOfSelectedChimney}
              renderNavigationButtons={false}
            >
              {selectedBuilding.chimneys.map((chimney) => {
                return (
                  <ChimneyPanel
                    key={chimney.id}
                    customerEnvironment={selectedBuilding.environment.id}
                    chimney={chimney}
                    selectedFlueMetric={selectedFlueMetricTimeItem}
                  />
                );
              })}
            </Deck>
          </Stack>
        </Box>
      }
    />
  );
};
