import { FC, ReactNode, useEffect } from "react";
import {
  BuildingImpl,
  EnvironmentAwareBuilding,
} from "@airmont/firefly/my-chimney/ts/building";
import { useAppInfo } from "shared-ts-app-info";
import { useUser } from "shared-ts-utils-authentication";
import {
  StringSetting,
  UserSettings,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { AddressId, FlueId } from "@airmont/firefly/shared/ts/domain";
import { BuildingSelectionPage } from "./BuildingSelectionPage";

export interface BuildingSelectionLoaderProps {
  buildings: Array<EnvironmentAwareBuilding>;
  children: (selectedBuilding: AddressId, selectedFlue: FlueId) => ReactNode;
}

export const BuildingSelectionLoader: FC<BuildingSelectionLoaderProps> = (
  props
) => {
  const { buildings } = props;
  const appInfo = useAppInfo();
  const user = useUser();
  const [selectedBuildingId, setSelectedBuildingId] =
    useUserSettingWithDefault<AddressId | null>(
      "building",
      StringSetting,
      null
    );

  const [selectedFlueId, setSelectedFlueId] =
    useUserSettingWithDefault<AddressId | null>(
      `${selectedBuildingId}.flue`,
      StringSetting,
      null
    );

  useEffect(
    function loadBuilding() {
      if (buildings.some((it) => it.id === selectedBuildingId)) {
        if (selectedFlueId == null && selectedBuildingId != null) {
          const selectedBuilding = buildings.find(
            (it) => it.id === selectedBuildingId
          );
          if (selectedBuilding !== undefined) {
            const flues = selectedBuilding.getFlues();
            if (flues.length > 0) {
              setSelectedFlueId(flues[0].id);
            }
          }
        }
      } else {
        setSelectedBuildingId(null);
        setSelectedFlueId(null);
      }
    },
    [
      selectedBuildingId,
      selectedFlueId,
      buildings,
      appInfo.key,
      user,
      setSelectedBuildingId,
      setSelectedFlueId,
    ]
  );

  const handleBuildingSelectionChange = (
    selectedBuildingId: AddressId,
    selectedFlueId?: FlueId
  ) => {
    const userSettings = new UserSettings({
      app: appInfo.key,
      user: user,
    });
    setSelectedBuildingId(selectedBuildingId);
    userSettings.write("building", selectedBuildingId, StringSetting);
    if (selectedFlueId != null) {
      setSelectedFlueId(selectedFlueId);
      userSettings.write(
        `${selectedBuildingId}.flue`,
        selectedFlueId,
        StringSetting
      );
    } else {
      const building = BuildingImpl.getById(selectedBuildingId, buildings);
      const flues = building.getFlues();
      if (flues.length > 0) {
        setSelectedFlueId(flues[0].id);
        userSettings.write(
          `${selectedBuildingId}.flue`,
          flues[0].id,
          StringSetting
        );
      }
    }
  };

  if (selectedBuildingId == null || selectedFlueId === null) {
    return (
      <BuildingSelectionPage
        buildings={buildings}
        selectedBuildingId={selectedBuildingId ?? undefined}
        selectedFlueId={selectedFlueId ?? undefined}
        onChange={handleBuildingSelectionChange}
      />
    );
  }

  return props.children(selectedBuildingId, selectedFlueId);
};
