import { FC } from "react";
import { Stack, SxProps } from "@mui/material";
import { useSxMerge } from "shared-ts-mui";

export interface MetricsContainerProps {
  className: string;
  children: React.ReactNode;
  sx?: SxProps;
}

export const MetricsContainer: FC<MetricsContainerProps> = (props) => {
  const sx = useSxMerge(props.sx, {
    flex: 1,
    gap: 2,
    minWidth: "140px",
    width: "140px",
    alignItems: "stretch",
    justifyContent: "center",
    padding: 2,
  });
  return (
    <Stack direction={"column"} className={props.className} useFlexGap sx={sx}>
      {props.children}
    </Stack>
  );
};
