import { FC, useMemo } from "react";
import { Stack, SxProps } from "@mui/material";
import { PeriodSelectUtils } from "./PeriodSelectUtils";
import { MonthItem } from "./MonthItem";
import { Month } from "./Month";
import { merge } from "lodash";
import { TimeframeProps } from "@airmont/shared/ts/ui/timeframe";
import { Button_color } from "shared-ts-mui";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import { FlueMetricsTimeUnitEnum } from "@airmont/firefly/shared/ts/domain";
import { useHotkeys } from "react-hotkeys-hook";
import { Key } from "ts-key-enum";
export interface PeriodSelectProps {
  timeframe: TimeframeProps;
  flue: Flue;
  color?: Button_color;
  sx?: SxProps;
}
export const PeriodSelect: FC<PeriodSelectProps> = (props) => {
  const { flue } = props;
  const months = PeriodSelectUtils.getMonths();

  useHotkeys(Key.ArrowLeft, () => {
    props.timeframe.onNextTimeframe();
  });

  useHotkeys(Key.ArrowRight, () => {
    props.timeframe.onPreviousTimeframe();
  });

  const sx = useMemo(
    () =>
      merge({}, props.sx, {
        overflowX: "auto",
        overflowY: "visible",
        position: "relative",
        "> .MuiButton-root": {
          marginBottom: "5px",
          marginRight: "5px",
          marginLeft: "2px",
          marginTop: "2px",
        },
      }),
    [props.sx]
  );

  const handleMonthSelect = (value: Month) => {
    props.timeframe.onTimeframeChange(value.toTimeframeObject());
  };

  return (
    <Stack direction={"row"} sx={sx}>
      {months.map((month, index) => {
        const selected = props.timeframe.timeframe.start.equals(month.dateTime);

        const metrics = flue.querySingleMetricsOrUndef(
          FlueMetricsTimeUnitEnum.Month,
          month.dateTime
        );

        return (
          <MonthItem
            key={month.asString}
            month={month}
            selected={selected}
            metrics={metrics}
            color={props.color}
            onSelect={handleMonthSelect}
          />
        );
      })}
    </Stack>
  );
};
