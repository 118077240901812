import { FC, useMemo } from "react";
import {
  BurnQualityCountAggregateByTimeLoader,
  BurnQualityCountDataPoints,
  FlueMetricNaturalKey,
  FlueMetricsTimeUnitEnum,
} from "@airmont/firefly/shared/ts/domain";
import { useBurnDao } from "@airmont/firefly/my-chimney/ts/burn";
import { DateTimeUnit, Interval } from "luxon";
import { MathUtils, notNull, notUndef } from "@airmont/shared/ts/utils/core";
import { CustomerEnvironmentId } from "@airmont/firefly/shared/ts/customer-environment";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import { useTimeframeProps } from "../history/useTimeframeProps";
import { useDateTimeNow } from "@airmont/shared/ts/utils/luxon";

export interface BurnQualityPercentLoaderProps {
  customerEnvironment: CustomerEnvironmentId;
  flue: Flue;
  selectedFlueMetric: FlueMetricNaturalKey;
  children: (result: { data: number | undefined }) => React.ReactNode;
}

export const BurnQualityPercentLoader: FC<BurnQualityPercentLoaderProps> = (
  props
) => {
  const { customerEnvironment, flue, selectedFlueMetric } = props;
  const burnDao = useBurnDao({
    customerEnvironment: props.customerEnvironment,
  });
  const now = useDateTimeNow();
  const timeframeProps = useTimeframeProps({
    flueMetricTimeItem: selectedFlueMetric,
    flue: flue,
    now: now,
  });

  const end = useMemo(() => {
    if (
      !untranslatableFlueMetricTimeUnits.includes(selectedFlueMetric.unit) &&
      selectedFlueMetric.time != null
    ) {
      return selectedFlueMetric.time?.plus({ [selectedFlueMetric.unit]: 1 });
    }
  }, [selectedFlueMetric.time, selectedFlueMetric.unit]);

  const aggregateBy: DateTimeUnit | undefined =
    untranslatableFlueMetricTimeUnits.includes(props.selectedFlueMetric.unit)
      ? undefined
      : (props.selectedFlueMetric.unit.toLowerCase() as unknown as DateTimeUnit);
  return (
    <>
      {timeframeProps != null && aggregateBy !== undefined && (
        <BurnQualityCountAggregateByTimeLoader
          flueId={flue.id}
          start={flue.audit.created}
          end={timeframeProps.timeframe.end}
          aggregateBy={aggregateBy}
          queryBurnQualityCountAggregate={
            burnDao.queryBurnQualityCountAggregate
          }
        >
          {(result) => {
            const dataPoints = notUndef(result.data, (data) => {
              let dataPoints1 = BurnQualityCountDataPoints.fromAggregates(data);
              if (end != null && data.length > 0) {
                const interval = Interval.fromDateTimes(
                  data[0].time.toLocal(),
                  end
                ) as Interval<true>;
                dataPoints1 = dataPoints1.between(interval, aggregateBy);
              }
              return dataPoints1;
            });
            
            const dataPoint = notUndef(dataPoints, (dataPoints) =>
              dataPoints.find((it) => {
                if (selectedFlueMetric.time != null) {
                  return it.time.equals(selectedFlueMetric.time.toUTC());
                }
                return undefined;
              })
            );

            return props.children({
              data: notUndef(
                dataPoint,
                (it) =>
                  notNull(it.goodInPercent, (it) => MathUtils.round(it)) ??
                  undefined
              ),
            });
          }}
        </BurnQualityCountAggregateByTimeLoader>
      )}
    </>
  );
};

const untranslatableFlueMetricTimeUnits = [
  FlueMetricsTimeUnitEnum.Ever,
  FlueMetricsTimeUnitEnum.SinceSweep,
  FlueMetricsTimeUnitEnum.AtSweep,
];
