import { AirmontLogoImg } from "shared-ts-ui-airmont";
import { AppInfo } from "shared-ts-app-info";

export const appKey = "ff-mc";
export const appName = "My Chimney";
export const appRelease = "2024.0";

export const appInfo: AppInfo = {
  key: appKey,
  name: appName,
  release: appRelease,
  icon: {
    large: <AirmontLogoImg />,
  },
};
