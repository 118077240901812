import {
  alpha,
  Box,
  Button,
  FormLabel,
  Popover,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import React, { FC, useCallback, useRef, useState } from "react";
import { Building } from "@airmont/firefly/my-chimney/ts/building";
import { FlueId } from "@airmont/firefly/shared/ts/domain";
import { Flue } from "@airmont/firefly/my-chimney/ts/building";
import { FlueBreadcrumbs } from "./FlueBreadcrumbs";
import { BuildingTreeView } from "./buildingTreeView/BuildingTreeView";
import { useSxMerge } from "shared-ts-mui";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useHotkeys } from "react-hotkeys-hook";
import { Key } from "ts-key-enum";

export interface FlueSelectProps {
  building: Building;
  selectedFlue?: Flue;
  onSelectFlue?: (flueId: FlueId) => void;
  sx?: SxProps;
}

export const FlueSelect: FC<FlueSelectProps> = (props) => {
  const { building, selectedFlue } = props;
  const theme = useTheme();
  const anchorRef = useRef(null);
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  const [hover, setHover] = useState(false);
  const [focused, setFocused] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  useHotkeys(
    "space",
    () => {
      if (open) {
        setAnchorEl(null);
      } else {
        setAnchorEl(anchorRef.current);
      }
    },
    {
      enabled: focused,
    },
    [focused]
  );
  const sx = useSxMerge(props.sx, {
    position: "relative",
    pl: 1,
    pr: 1,
    pt: "7px",
    pb: "7px",
    cursor: "pointer",
    "> label": {
      position: "absolute",
      transformOrigin: "top left",
      whitespace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "calc(133% - 32px)",
      left: 0,
      top: 0,
      transform: "translate(14px, -9px) scale(0.75)",
      transition:
        "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      zIndex: 1,
      pointerEvents: "auto",
      userSelect: "none",
    },
    "> fieldset": {
      position: "absolute",
      pointerEvents: "none",
      top: "-10px",
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: "5px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: hover
        ? theme.palette.text.primary
        : alpha(
            theme.palette.mode === "light"
              ? theme.palette.common.black
              : theme.palette.common.white,
            0.23
          ),
      padding: "0px 8px",
      legend: {
        float: "unset",
        width: "auto",
        overflow: "hidden",
        padding: 0,
        fontSize: "0.75em",
        maxWidth: "100%",
        visibility: "hidden",
        transition: "max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms",
        whiteSpace: "nowrap",
        span: {
          pl: "5px",
          pr: "5px",
          opacity: 0,
          visibility: "visible",
        },
      },
    },
    "&.Mui-focused, &:focus, &:focus-within, &:focus-visible, &:active": {
      outline: "unset",
      "> label": {
        color: theme.palette.primary.main,
      },
      "> fieldset": {
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",
      },
    },
  });
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorRef.current);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelectFlue = useCallback(
    (flueId: FlueId) => {
      if (props.onSelectFlue != null) {
        props.onSelectFlue?.(flueId);
        handleClose();
      }
    },
    [props]
  );

  const handleMouseEnter = useCallback(() => {
    setHover(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  return (
    <Stack
      ref={anchorRef}
      className={"FlueSelect" + (focused ? " Mui-focused" : "")}
      direction={"row"}
      sx={sx}
      tabIndex={0}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <FormLabel component={"label"}>{t("Flue")}</FormLabel>
      <Box component={"fieldset"} sx={{}}>
        <Typography component={"legend"} variant={"caption"}>
          <span>{t("Flue")}</span>
        </Typography>
      </Box>
      {selectedFlue !== undefined && (
        <FlueBreadcrumbs
          flue={selectedFlue}
          onClick={handleClick}
          noWrap={true}
        />
      )}
      {selectedFlue === undefined && (
        <Button onClick={handleClick} size={"small"}>
          Velg røykløp
        </Button>
      )}
      {open && <ArrowDropUpIcon />}
      {!open && <ArrowDropDownIcon />}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <BuildingTreeView
          building={building}
          hideBuildingTreeItem={true}
          selectedFlue={selectedFlue?.id}
          onSelectFlue={handleSelectFlue}
        />
      </Popover>
    </Stack>
  );
};
