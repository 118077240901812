import {
  AuditInfo,
  AuditInfoDto,
  FlueFieldsDto,
  FlueId,
  FlueMetrics,
  FlueMetricsDto,
  FlueMetricsTimeUnitEnum,
} from "@airmont/firefly/shared/ts/domain";
import { Fireplace, FireplaceDto } from "../fireplace/Fireplace";
import { Typed } from "@airmont/firefly/my-chimney/ts/shared";
import { DateTime } from "luxon";
import {
  IllegalStateError,
  UnsupportedError,
} from "@airmont/shared/ts/utils/core";
import { DateTimeUnit } from "luxon/src/datetime";
import { Chimney } from "../Chimney";

export interface McFlueFieldsDto {
  name: string | null;
}

export interface FlueDto extends Typed<"Flue"> {
  id: FlueId;
  audit: AuditInfoDto;
  mcFields: McFlueFieldsDto | null;
  fields: FlueFieldsDto;
  fireplaces: Array<FireplaceDto>;
  metrics: Array<FlueMetricsDto>;
}

export interface FlueCopyArgs {
  mcFields?: McFlueFieldsDto | null;
  fireplaces?: Array<Fireplace>;
}

export interface Flue extends Typed<"Flue"> {
  id: FlueId;
  audit: AuditInfo;
  mcFields: McFlueFieldsDto | null;
  fields: FlueFieldsDto;
  metrics: Array<FlueMetrics>;
  fireplaces: Array<Fireplace>;
  chimney: Chimney | undefined;
  setChimney: (chimney: Chimney) => void;
  addFireplace: (fireplace: Fireplace) => void;
  querySingleMetricsOrUndef: (
    timeUnit: FlueMetricsTimeUnitEnum,
    time?: DateTime
  ) => FlueMetrics | undefined;
  copy: (args: FlueCopyArgs) => Flue;
}

export class FlueImpl implements Flue {
  readonly type = "Flue";
  readonly id: FlueId;
  readonly audit: AuditInfo;
  readonly mcFields: McFlueFieldsDto | null;
  readonly fields: FlueFieldsDto;
  readonly metrics: Array<FlueMetrics>;
  readonly fireplaces: Array<Fireplace>;
  chimney: Chimney | undefined;

  constructor(args: {
    id: FlueId;
    audit: AuditInfo;
    mcFields: McFlueFieldsDto | null;
    fields: FlueFieldsDto;
    metrics: Array<FlueMetricsDto> | Array<FlueMetrics>;
    fireplaces?: Array<Fireplace>;
    chimney?: Chimney;
  }) {
    this.id = args.id;
    this.audit = args.audit;
    this.mcFields = args.mcFields;
    this.fields = args.fields;
    this.metrics =
      args.metrics.length === 0
        ? []
        : args.metrics[0] instanceof FlueMetrics
        ? (args.metrics as Array<FlueMetrics>)
        : args.metrics.map((it) =>
            it instanceof FlueMetrics ? it : new FlueMetrics(it)
          );
    this.fireplaces = args.fireplaces ?? [];
    this.chimney = args.chimney;
  }

  copy(args: FlueCopyArgs): Flue {
    const copy = new FlueImpl({
      id: this.id,
      audit: this.audit,
      mcFields: args.mcFields ?? this.mcFields,
      fields: this.fields,
      metrics: this.metrics,
      fireplaces: args.fireplaces ?? this.fireplaces,
      chimney: this.chimney,
    });
    copy.fireplaces.forEach((fireplace) => {
      fireplace.setFlue(copy);
    });
    return copy;
  }

  setChimney(chimney: Chimney) {
    this.chimney = chimney;
  }

  addFireplace(fireplace: Fireplace) {
    fireplace.setFlue(this);
    this.fireplaces.push(fireplace);
  }

  querySingleMetricsOrUndef(
    timeUnit: FlueMetricsTimeUnitEnum,
    time: DateTime | undefined
  ): FlueMetrics | undefined {
    let result: FlueMetrics[] = [];
    if (
      timeUnit === FlueMetricsTimeUnitEnum.Ever ||
      timeUnit === FlueMetricsTimeUnitEnum.SinceSweep
    ) {
      result = this.metrics.filter((it) => {
        return timeUnit === it.timeUnit;
      });
    } else if (time != null) {
      const resolveUnit = (timeUnit: FlueMetricsTimeUnitEnum): DateTimeUnit => {
        if (timeUnit === FlueMetricsTimeUnitEnum.Month) {
          return "month";
        } else if (timeUnit === FlueMetricsTimeUnitEnum.Year) {
          return "year";
        } else {
          throw new UnsupportedError("timeUnit: " + timeUnit);
        }
      };
      const unit = resolveUnit(timeUnit);
      const timeStartOfUnit = time.startOf(unit).toUTC();

      result = this.metrics.filter((it) => {
        if (timeUnit !== it.timeUnit) {
          return false;
        }
        if (time === undefined) {
          return true;
        }
        if (it.time === undefined) {
          return false;
        }
        return timeStartOfUnit.equals(it.time.toUTC());
      });
    }

    if (result.length === 0) {
      return undefined;
    } else if (result.length === 1) {
      return result[0];
    } else {
      throw new IllegalStateError(
        "Unexpected number of elements in result, expected 0 or 1, got: " +
          result.length
      );
    }
  }
}
