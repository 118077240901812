import React, { FC } from "react";
import { Box, Divider } from "@mui/material";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import { Flue, FlueUpdateSpec } from "@airmont/firefly/my-chimney/ts/building";
import { useTranslation } from "react-i18next";
import { CustomerEnvironmentId } from "@airmont/firefly/shared/ts/customer-environment";
import { FlueForm } from "./FlueForm";
import { Property } from "shared-ts-property";

export interface FluePanelProps {
  environment: CustomerEnvironmentId;
  flue: Flue;
  onChange: (update: FlueUpdateSpec) => void;
}

export const FluePanel: FC<FluePanelProps> = (props) => {
  const { flue, environment } = props;
  const { t: tFireflyDomain } = useTranslation("firefly-shared-ts-domain");
  const { t } = useTranslation("firefly-my-chimney-ts-pages");

  return (
    <Box>
      <PropertiesCard
        header={{
          title: `${t("Flue")}${
            flue.mcFields?.name != null ? `: ${flue.mcFields?.name ?? ""}` : ""
          }`,
          subtitle: t(
            "The fire department has recorded the following information about the flue"
          ),
        }}
        elevation={0}
      >
        <Property label={tFireflyDomain("Name")} value={flue.fields.name} />
        <Property label={tFireflyDomain("Type")} value={flue.fields.type} />
        <Property label={tFireflyDomain("Height")} value={flue.fields.height} />
      </PropertiesCard>
      {flue.mcFields != null && (
        <>
          <Divider />
          <FlueForm
            flue={flue}
            environment={environment}
            onChange={props.onChange}
          />
        </>
      )}
    </Box>
  );
};
