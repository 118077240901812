import { Alert, AlertTitle, Button, Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import { FormikHelpers } from "formik/dist/types";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import React, { FC, useCallback, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useIdentityDao } from "./useIdentityDao";
import { LoginState } from "./LoginState";
import { Property } from "shared-ts-property";

type LoginFormValues = {
  username: string;
  password: string;
};

export interface LoginFormProps {
  username?: string | null;
  loginState: LoginState | undefined;
  onLoginStateChange: (result: LoginState) => void;
  onRegisterNewUser: () => void;
}

export const LoginForm: FC<LoginFormProps> = (props) => {
  const { username, loginState, onLoginStateChange, onRegisterNewUser } = props;
  const { t } = useTranslation("shared-ts-ui-identity");

  const [sendResetPasswordEmailStatus, setSendResetPasswordEmailStatus] =
    useState<"none" | "sending" | "sent">("none");

  const identityDao = useIdentityDao();

  const initialValues: LoginFormValues = {
    username: username ?? "",
    password: "",
  };

  const handleNewUserClick = useCallback(
    () => onRegisterNewUser(),
    [onRegisterNewUser]
  );
  const handleSubmit = async (
    values: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>
  ) => {
    setSendResetPasswordEmailStatus("none");
    const result = await identityDao.loginUsingCookies({
      email: values.username,
      password: values.password,
    });
    formikHelpers.setSubmitting(false);
    onLoginStateChange(result);
  };

  const handleForgotPassword = async (values: LoginFormValues) => {
    setSendResetPasswordEmailStatus("sending");
    await identityDao.sendResetPasswordEmail({ email: values.username });
    setSendResetPasswordEmailStatus("sent");
  };

  return (
    <>
      {loginState === "CheckingAccess" && (
        <Typography variant={"h5"} align={"center"}>
          {t("Checking Access")}
        </Typography>
      )}
      {loginState !== "CheckingAccess" && (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ dirty, values, submitForm, isSubmitting, setFieldValue }) => {
            const handleLoginClick = () => {
              submitForm();
            };

            const handleForgotPasswordClick = () => {
              handleForgotPassword(values);
            };

            return (
              <PropertiesCard
                header={{
                  title: t("Login"),
                }}
                sx={{
                  justifyContent: "center",
                }}
              >
                <Property
                  name={"username"}
                  label={t("Email")}
                  value={values.username}
                  mode={"edit"}
                  fullWidth
                  onChange={(value, name) => setFieldValue(name, value)}
                />
                <Property
                  name={"password"}
                  label={t("Password")}
                  type={"password"}
                  value={values.password}
                  mode={"edit"}
                  fullWidth
                  onChange={(value, name) => setFieldValue(name, value)}
                />
                <Stack
                  direction={"row"}
                  sx={{ width: "100%", justifyContent: "space-evenly" }}
                >
                  <LoadingButton
                    color={"secondary"}
                    variant={"contained"}
                    disabled={!dirty}
                    loading={isSubmitting}
                    onClick={handleLoginClick}
                  >
                    {t("Login")}
                  </LoadingButton>
                </Stack>
                {loginState === "Unauthorized" &&
                  sendResetPasswordEmailStatus !== "sent" && (
                    <Alert severity={"warning"}>
                      {t("Wrong username or password")}
                    </Alert>
                  )}
                <Stack
                  direction={"row"}
                  sx={{ width: "100%", justifyContent: "space-evenly" }}
                >
                  <Button color={"secondary"} onClick={handleNewUserClick}>
                    {t("New User")}
                  </Button>
                  <LoadingButton
                    color={"secondary"}
                    variant={"text"}
                    disabled={!dirty}
                    loading={sendResetPasswordEmailStatus === "sending"}
                    onClick={handleForgotPasswordClick}
                  >
                    {t("Forgot Password")}
                  </LoadingButton>
                </Stack>
                {sendResetPasswordEmailStatus === "sent" && (
                  <Alert severity={"info"}>
                    <AlertTitle>
                      {" "}
                      {t("An e-mail for resetting password will be sent to")}
                      {": " + values.username}
                    </AlertTitle>
                    {t("if this e-mail matched an existing user")}
                  </Alert>
                )}
              </PropertiesCard>
            );
          }}
        </Formik>
      )}
    </>
  );
};
